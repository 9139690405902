import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toastr} from 'react-redux-toastr'
import _crudService  from "@services/crud.service"


export const horariuData = createAsyncThunk(
  "horariu/data",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await _crudService._data(dato,'horarius');                                                  
    
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)



export const horariuUpdate = createAsyncThunk(
  "horariu/update",
  async(dato,thunkAPI)=>{
      try{      
          const data = await _crudService._update(dato,'horarius');  
          toastr.success('horariu', 'Actualizada')                                       
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)



const initialState = { 
  loading:false,
  datas:[]  
};  


const horariuSlice = createSlice({
  name: "horariu",
  initialState,  
  reducers:{
    resetItem:(state)=>{
      state.item ={}
    },
    sethorariu:(state,action)=>{
      state.items = action.payload
    },
    resetData:(state)=>{
      state.datas    = []  
    },    
  },
  extraReducers(builder) { 
    builder
     
      .addCase(horariuData.pending,(state) =>{
        state.loading = true            
      })
      .addCase(horariuData.fulfilled,(state,action)=>{
          state.loading = false
          state.datas  = action.payload.response
      })
      .addCase(horariuData.rejected,(state)=>{
        state.loading = false
      
      }) 
  
  
      /** update */
      .addCase(horariuUpdate.pending,(state) =>{
        state.loading = true            
        state.item    = {}
      })
      .addCase(horariuUpdate.fulfilled,(state,action)=>{
        state.loading = false
        state.datas  = action.payload.response
      })
      .addCase(horariuUpdate.rejected,(state)=>{
        state.loading = false
        state.item    = {}
      })
    
    
  }    
});
export const { resetItem, resetData, sethorariu } = horariuSlice.actions
const { reducer } = horariuSlice;
export default reducer;  
