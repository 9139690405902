import { useState, useEffect } from 'react'
import Moment from "react-moment"
import { apiUrl } from '@helpers'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon,ChevronDoubleRightIcon, XMarkIcon, CheckIcon, CalendarDaysIcon, TrashIcon } from "@heroicons/react/24/outline";
import {  _pag } from '@data/dataLoad'
import SelectUnit from '@components/selects/SelectUnit'

const TableSucursal = ({data,indicador,setIndicador,pagina,paginas,total,handleData,handleDelete}) => {
    let ep  = JSON.parse(localStorage.getItem('@empresaUnity22'))
    const [num, setnum] = useState(12);              


    let renderPageNumbers;
    const pageNumber = [];
    if (total !== null) {
        for (let i = 1; i <= paginas; i++) {
          pageNumber.push(i);
        }
        renderPageNumbers = pageNumber.map((number) => {
          let classes = pagina === number ? "border-r-2 h-5 w-5 border-white rounded text-center bg-sky-300 text-white hover:bg-sky-400 text-xs font-bold" 
          : "border-r-2 h-5 w-5 text-gray-500 border-white text-center bg-sky-50 rounded hover:text-white hover:bg-sky-400 focus:outline-none disabled:opacity-25 disabled text-xs";
    
          if (
            number === 1 ||
            number === total ||
            (number >= pagina - 2 && number <= pagina + 2)
          ) {
            return (
              <button
                key={number}
                className={classes}
                onClick={() => handleData(number,num)}
              >
                {number}
              </button>
            );
          } else {
            return null;
          }
        });
    }

    const handlePage = (val) =>{                  
        handleData(pagina,val)  
        setnum(val)
      }
      useEffect(() => {
        setnum(12)
        return () => {
          
        };
      }, []);



   
  
return (    
    <div className="flex-col w-full">        
        <div className="flex-1 mx-auto">                
              <table className="border-collapse w-full text-[11px] border-2 text-gray-600">
                <thead>
                    <tr className='h-7 border bg-gray-100'>
                       <th className='w-1/12 border-r boder-gray-300'>
                        ID
                       </th>     
                       <th className='w-4/12 border-r boder-gray-300'>
                        Nombre 
                       </th>     
                       <th className='w-2/12 border-r boder-gray-300'>
                        Ciudad 
                       </th>     
                       <th className='w-4/12 border-r boder-gray-300'>
                        Dirección
                       </th>  
                       <th className='w-1/12 border-r boder-gray-300'>
                        
                       </th>                             
                    </tr>
                </thead>
                <tbody>
                
                   { data.map((it,index)=> (
                        <tr 
                        className="h-8 border"
                        key={index}>
                            <td className='text-center border-r border-gray-300'>
                                <input type="checkbox" 
                                onChange={() => { setIndicador(it.id,it) }}   
                                checked={ it.id === indicador ? true : false}                          
                                />
                            </td>
                            <td className='pl-1 border-r border-gray-300'>
                                {it.nombre}</td>
                            <td className='text-center border-r border-gray-300'>
                                {it.ciudad}</td>
                            <td className='pl-1 border-r border-gray-300'>
                                {it.direccion}</td>

                            <td className='text-center pl-3'>
                              <button
                              className={ it.id === indicador ? ' border w-10 hidden' : 'h-7 bg-red-400 border-red-400 w-10 text-gray-50 rounded flex items-center justify-center'} 
                              onClick={()=>handleDelete(it.id)}
                              >
                                <TrashIcon  className="h-4 w-4 text-gray-50" /> 
                              </button>                              
                            </td>
                            
                        </tr>            
                    ))}
                </tbody>
              </table>
        </div>  

        <div className="h-10 border-b border-l border-r flex  bg-gray-50">
            <div className="w-2/6 flex justify-start items-center pr-1 pt-1">
                <div className='w-2/3 flex'>
                <label className='w-32 text-[10px] text-gray-600 pl-2'>Mostrar {num} de {total} items </label>
                </div>
                <div className='w-1/3 flex'>
                  <SelectUnit
                    options={_pag}
                    option={num}
                    handleChange={handlePage}
                  />
                </div> 
            </div>
            <div className="w-4/6 flex justify-end">
                <ul className="flex p-1 text-xs">                
                    <li 
                    className={pagina === 1 ? "border rounded border-gray-200 flex items-center justify-center h-6 w-6 text-gray-400 mr-1 cursor-not-allowed p-1": "border rounded border-gray-300 flex items-center h-6 w-6 text-gray-400 mr-1 hover:bg-gray-200 justify-center p-1" } 
                    onClick={() => handleData(pagina === 1 ? 0 : 1,num)}>        
                    <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400"/>
                    </li>

                    <li
                    className={pagina === 1 ? "border rounded border-gray-200 flex items-center h-6 w-6 justify-center text-gray-400 mr-1 cursor-not-allowed p-1": "border rounded border-gray-300 flex items-center h-6 w-6 text-gray-400 mr-1 hover:bg-gray-200 justify-center p-1" } 
                    onClick={() =>handleData(pagina === 1 ? 0: pagina - 1,num)}>        
                    <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
                    </li>

                    <li
                    className="h-6 flex items-center">        
                    {renderPageNumbers}
                    </li>        
                    
                    <li        
                    className={pagina === paginas ? "border rounded border-gray-200 flex items-center justify-center h-6 w-6 text-gray-400 mr-1 cursor-not-allowed p-1": "border rounded border-gray-300 flex items-center h-6 w-6 text-gray-400 mr-1 hover:bg-gray-200 justify-center p-1" } 
                    onClick={() => handleData(pagina === paginas ? 0 : pagina + 1,num)}>          
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </li>

                    <li
                    className={paginas === pagina ? "border rounded border-gray-200   flex items-center justify-center h-6 w-6 text-gray-400 mr-1 cursor-not-allowed p-1": "border rounded border-gray-300 h-6 w-6 text-gray-400 mr-1 hover:bg-gray-200 flex items-center justify-center p-1" } 
                    onClick={() => handleData(pagina === paginas ? 0: paginas,num)}>
                    <ChevronDoubleRightIcon className="h-4 w-4 text-gray-500" />  
                    </li>     
                </ul>
            </div>        
        </div>                
    </div>
    );
}

export default TableSucursal;
