import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toastr} from 'react-redux-toastr'
import _crudService  from "@services/crud.service"

export const consultasData = createAsyncThunk(
    "consultas/data",
    async(dato,thunkAPI)=>{
        try{                    
          const data = await _crudService._data(dato,'consultas');  
          
          return { response: data }
        }catch(error){                  
            return thunkAPI.rejectWithValue();           
        }   
    }
)


  const initialState = { 
    loading:false,
    resp:"",
    item:{},        
    data:[],
    total:0,
    pagina:0,
    paginas:0,  
  };  
  const consultaSlice = createSlice({
    name: "consulta",
    initialState,  
    reducers:{
      resetItem:(state)=>{
        state.item ={}        
      },
      resetData:(state)=>{
        state.data    = []          
        state.total   = 0
        state.pagina  = 0
        state.paginas = 0   
      },    
    },
    extraReducers(builder) { 
        builder
        .addCase(consultasData.pending,(state) =>{
              state.loading = true            
          })
          .addCase(consultasData.fulfilled,(state,action)=>{
              state.loading = false
              state.data  = action.payload.response.data    
              state.total = action.payload.response.total          
              state.pagina = action.payload.response.pagina          
              state.paginas = action.payload.response.paginas          
          })
          .addCase(consultasData.rejected,(state)=>{
            state.loading = false
           
          })   
         
      }    
    });
    export const { resetItem, resetData} = consultaSlice.actions
    const { reducer } = consultaSlice;
    export default reducer;  
    


